import React from 'react';
import logo from '../css/images/BBM-logo-holly.png';
// import LetterTopImg from '../css/images/letter-top-shadow.png';

class Letter extends React.Component {
    render() {
        return(
            <div className="santa-letter">
                <div className="letter-top">
                    {/* <img src={LetterTopImg}/> */}
                </div>
                <div className="letter-body">
                    <div className="letter-gradient">
                        <h1>Ho Ho Ho, The Places We'll Snow!</h1>     
                        <p>“Merry Christmas and Kwanzaa and Chanukah too—<br />
                        All the holiday <strong>CHEER</strong> we can muster for <strong>YOU</strong>!”<br />
                        So cries Willy (our spokesman) now gifted in globe.<br />
                        On his head a brown bag (“what’s ‘neath it?” you probe)!</p>
    
                        <p>From the <strong>HEIGHTS</strong> of our tower in Buckhead we say:<br />
                        “Let us shake it, and shake up, and <strong>SHAKE YOU</strong> today!<br />
                        With your mission and market and branding in mind,<br />
                        We work hard and play harder for <strong>INSIGHTS</strong> to find!”</p>

                        <p>So please tiggle that toggle and <strong>SWIZZLE</strong> the scene!<br />
                        As to how we did put him betwixt and between,<br />
                        It’s that <strong>THING</strong> that we are, with our code and our copy<br />
                        And designing and drawing: a full-service shoppie!</p>
                        <p>In the new year to come new <strong>IDEAS</strong> will flow<br />
                        And we relish the prospect of campaigns that <strong>GROW</strong>.<br />
                        From the soup to the nuts, from the dream to the deed,<br />
                        We see you. Come see us: we’ll help you <strong>SUCCEED!</strong></p>

                        <img className="bbm-logo" src={logo} alt="Brown Bag Marketing"/>
                        
                    </div>
                </div>
                <div className="letter-bottom"></div>
            </div>
        );
    }
}

export{Letter};