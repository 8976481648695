import React from 'react';
import { Shake } from 'reshake';

import { SnowglobeBase } from './SnowglobeBase';

class Snowglobe extends React.Component {
  constructor(props) {
    super(props);
    this.scenes = ["willy-bbm", "steel-willy", "willy-workshop"];
    this.state = {isToggleOn: false, willyOn:true, sceneClass:this.scenes[0]};
 
    this.myvar = null;
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    //this.myFunction = this.myFunction.bind(this);
    this.alertFunc = this.alertFunc.bind(this);
  }


  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn,
      //willyOn: !state.willyOn 
    }));
    
    this.myVar = setTimeout(this.alertFunc, 1000);
    
  }
  

alertFunc(_timer) {
  this.setState(state => ({
    isToggleOn: !state.isToggleOn,
    sceneClass: this.switchScenes()
  }));
}

switchScenes(){
  var currentClass = this.state.sceneClass;
  var newClass = this.state.sceneClass;;
  do{
    newClass = this.scenes[Math.floor(Math.random() * this.scenes.length)];
  }while(currentClass === newClass )
  return newClass;
}

  render() {
    return (
      
        <div>

<div className="snowglobe-with-trees">
<div className="trees-left"></div>
<Shake h={75} v={75} r={5} active={this.state.isToggleOn} fixed={true}
onClick={() => this.handleClick()}>
<div className="snowglobe">
  <div className="sg-bg"></div>
  <div className="grnd-snow">
    <div className="snow-surface"></div>
  </div>
  <div className={this.state.sceneClass}>
  {/* {this.state.sceneClass === "steel-willy" ? (<SteelWilly></SteelWilly>) : ("") } */}
  </div>
  <div className="falling-snow-wrap">
    <div className="falling-snow">
      <div className="flakes1">
        <p>*</p>
      </div>
    <div className={"shake1 " + (!this.state.isToggleOn ? "hidden":"")}>
        <p>*</p>
    </div>
    <div className="flakes2">
        <p>*</p>
    </div>
    <div className={"shake2 "  + (!this.state.isToggleOn ? "hidden":"")}>
        <p>*</p>
    </div>
  </div>
</div>
<div className="sg-fg"></div>
<SnowglobeBase></SnowglobeBase>
</div></Shake>
<div className="trees-right"></div>
</div>
</div>
    );
  }
}

export{Snowglobe};