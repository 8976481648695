import React from 'react';
import './css/globe.min.css';
import { Snowglobe } from './classes/Snowglobe';
import { Letter } from './classes/Letter';

function Globe() {
  
    return (
      <div className="snowBackground">
      <Letter></Letter>
      <Snowglobe></Snowglobe>
      </div>
    );
    

  }
  
export default Globe;
  